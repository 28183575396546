import { Switch } from "@mantine/core";
import type { CellContext } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";

import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { useUpdateScreenerLocationMutation } from "@/hooks/queries/screener-locations.queries";
import {
  IndividualScreenerLocationStatus,
  type IndividualScreenerLocationStatusType,
  type ScreenerLocation,
} from "@/types/screener-location";
import {
  formatLocationOrDraftForServer,
  locationIsLive,
} from "@/utils/screener-location.utils";
import { stopPropagationWrapper } from "@/utils/ui";

interface LocationStatusCellProps {
  info: CellContext<ScreenerLocation, IndividualScreenerLocationStatusType>;
}

export const LocationStatusCell = ({ info }: LocationStatusCellProps) => {
  const { mutate: updateStatus } = useUpdateScreenerLocationMutation();
  const track = useTrackAnalytics();
  const [status, isLive] = useMemo(() => {
    const status = info.getValue();

    return [status, locationIsLive(status)] as const;
  }, [info]);

  const handleChange = useCallback(() => {
    const newStatus = isLive
      ? IndividualScreenerLocationStatus.Paused
      : IndividualScreenerLocationStatus.Live;

    track("Status Toggled", {
      location_id: info.row.original.id,
      status: newStatus,
    });

    updateStatus({
      locationId: info.row.original.id,
      screenerLocation: formatLocationOrDraftForServer({
        ...info.row.original,
        status: newStatus,
      }),
    });
  }, [info, isLive, track, updateStatus]);

  // Wrapper div onClick to prevent the click from bubbling up to the row click
  // handler. The Mantine onClick seems to be fired from a different element
  // making stopping propagation within the Switch change handler infeasible
  return (
    <div onClick={stopPropagationWrapper()}>
      <Switch checked={isLive} label={status} onClick={handleChange} />
    </div>
  );
};
