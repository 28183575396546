import pick from "lodash/pick";

import {
  type CleanLocationDraftForServer,
  IndividualScreenerLocationStatus,
  IndividualScreenerLocationStatusType,
  ScreenerLocation,
  type ScreenerLocationDraft,
} from "@/types/screener-location";
import { numberIsTruthyOrZero } from "@/utils/helpers";

export const locationIsLive = (
  locationOrStatus:
    | IndividualScreenerLocationStatusType
    | { status: IndividualScreenerLocationStatusType },
) =>
  (typeof locationOrStatus === "string"
    ? locationOrStatus
    : locationOrStatus?.status) === IndividualScreenerLocationStatus.Live;

const propertiesForServerDraft = [
  "atsJobId",
  "locationName",
  "maxPayHourly",
  "minPayHourly",
  "shareLinkSlug",
  "status",
] as const;

export const formatLocationOrDraftForServer = <
  T extends ScreenerLocation | ScreenerLocationDraft,
>(
  location: T,
): CleanLocationDraftForServer => {
  const cleaned = pick<T, (typeof propertiesForServerDraft)[number]>(
    location,
    propertiesForServerDraft,
  );
  const { maxPayHourly, minPayHourly, shareLinkSlug } = cleaned;

  return {
    ...cleaned,
    maxPayHourly: numberIsTruthyOrZero(maxPayHourly) ? maxPayHourly : null,
    minPayHourly: numberIsTruthyOrZero(minPayHourly) ? minPayHourly : null,
    shareLinkSlug: shareLinkSlug?.trim() ?? null,
  };
};
